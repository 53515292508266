<template>
  <div class="drama" v-loading='mainLoading'>
    <div class="drama_main">
      <div class="drama_main_l">
        <div class="title">基本信息</div>
        <div class="line"></div>
        <div class="cover">
          <el-image style="width: 100%; height: 100%" :src="form.cover_url" fit="cover"></el-image>
          <div class="cover_b" @click="openCover">
            专辑封面
          </div>
        </div>
        <el-form :model="form" :rules="rules" ref="form" label-width="0px">
          <el-form-item prop="name">
            <div class="tit"><span>*</span>台本标题</div>
            <div class="input">
              <el-input v-model="form.name" placeholder="请输入台本标题"></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="tit">选择专辑</div>
            <div class="input">
              <el-select style="width: 100%;" v-model="form.album_id" placeholder="请选择专辑">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="tit">选择分类</div>
            <div class="input">
              <el-cascader :props="projectTypeProps" style="width: 100%;" size="small"
                v-model="form.category_path"
                :key="editCascader"
                @change='changeBtn'
                placeholder="请选择分类">
              </el-cascader>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="drama_main_r">
        <upload-btn @analysisSuccess="analysisSuccess" @analysisText="analysisText"></upload-btn>
        <div class="tit">台本文本：</div>
        <el-input style="width: 100%;" type="textarea" placeholder="请输入内容" v-model="form.description" rows="18">
        </el-input>
        <div class="tit">台本录音：</div>
        <div class="audio_block" v-if="form.sound_url">
          <audio ref="audio" controls="controls" :src="form.sound_url"></audio>
          <el-button class="btn" size="small" type="primary" v-if="form.description!=''">重新合成</el-button>
        </div>
        <div class="audio_block" v-else>
          <p v-if="form.description==''">快来填写文本，生成有声台本吧</p>
          <p v-else>待解析录音，开始<span @click="analysis">解析</span>吧～</p>
        </div>
      </div>
    </div>
    <div class="drama_footer">
      <el-button class="btnBgColor_blue_empty" size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button class="btnBgColor_blue" size="small" type="primary" :loading="loading" @click="confirm('form')">保
        存</el-button>
    </div>
    <!-- 更换专辑封面 -->
    <set-cover ref="setCover" @editCover="editCover"></set-cover>
    <!-- 解析链接 -->
    <analysis-audio ref="analysisAudio"></analysis-audio>
  </div>
</template>

<script>
  import SetCover from '../components/set_cover/index.vue' //更换专辑封面
  import UploadBtn from '../components/upload_btn/index.vue' //新增解析
  import AnalysisAudio from '../components/analysis_audio/index.vue' //解析音频
  export default {
    components: {
      SetCover,
      AnalysisAudio,
      UploadBtn
    },
    data() {
      return {
        loading: false,
        editCascader:0,
        projectTypeProps: {
          lazy: true,
          checkStrictly: true,
          lazyLoad: (node, resolve) => {
            this.typeLazyLoad(node, resolve);
          }
        },
        form: {
          name: '',
          album_id: '',
          category_id: '',
          category_path:[],
          description: '',
          sound_url: '',
          cover_url: ''
        },
        rules: {
          name: [{
            required: true,
            message: '请输入台本标题',
            trigger: 'blur'
          }, ],
        },
        options: [],
        mainLoading: false,
        id: null,
      };
    },
    created() {
      this.getAlbumList()
    },
    mounted() {
      if(this.$route.params.id){
        this.$nextTick(()=>{
          if (this.$route.params.id == -1) {
            // 创建
            return
          } else if (this.$route.params.id == -2) {
            // 引用
            this.quoteDetails(this.$route.query.id)
          } else if (this.$route.params.id == -3) {
            // 链接/视频转台本
            if(this.$store.state.book_text){
              this.form.description=this.$store.state.book_text
              var info = {
                type:1,
                val:''
              }
              this.$store.commit('SET_BOOK',info)
            }else if(this.$store.state.book_url){
              this.form.sound_url=this.$store.state.book_url
              var info = {
                type:2,
                val:''
              }
              this.$store.commit('SET_BOOK',info)
            }
          }  else {
            var name = '编辑台本'
            var key = this.$route.meta.key + '_' + this.$route.params.id
            this.$store.dispatch('tabsArr/getName', { name, key })
            // 编辑
            this.id = this.$route.params.id
            this.getDetails()
          }
        })
      }
    },
    methods: {
      // 解析其余成功
      analysisText(val) {
        this.form.description = val
      },
      // 解析链接成功
      analysisSuccess(val, type) {
        if (type == 1) {
          this.form.description = val
        } else {
          this.form.sound_url = val
        }
      },
      // 解析链接
      analysis() {
        this.$refs.analysisAudio.open()
      },
      // 修改封面
      openCover() {
        this.$refs.setCover.open()
      },
      // 封面
      editCover(val) {
        console.log(val)
        this.form.cover_url = val

      },
      confirm(formName) {
        var that = this
        that.$refs[formName].validate((valid) => {
          if (valid) {
            if (that.form.description == '' && that.form.sound_url == '') {
              that.$errMsg('请输入台本文本信息或音频信息')
              return
            }
            that.loading = true;
            let obj = {
              name: that.form.name,
              album_id: that.form.album_id,
              category_id: that.form.category_id,
              cover_url: that.form.cover_url,
              description: that.form.description,
              sound_url: that.form.sound_url,
            }
            if (that.id) {
              // 编辑
              that.$workbenApi.editConvertToText(obj, that.id).then(res => {
                that.loading = false;
                if (res.code == 1000) {
                  that.$succMsg(res.message)
                  setTimeout(function(){
                    that.$store.dispatch('tabsArr/delTabList')
                  },2000)
                } else {
                  that.$errMsg(res.message)
                }
              })
            } else {
              // 新增
              that.$workbenApi.addScript(obj).then(res => {
                that.loading = false;
                if (res.code == 1000) {
                  that.$succMsg(res.message)
                  setTimeout(function(){
                    that.$store.dispatch('tabsArr/delTabList')
                  },2000)
                } else {
                  that.$errMsg(res.message)
                }
              })
            }

          } else {
            return
          }
        });
      },
      //播放组件
      handlePlay(row) {
        this.src = row.filePath;
        this.play();
      },
      //播放
      play() {
        this.dialogVisible = true;
        this.$refs.audio.play();
      },
      //音频暂停
      stop() {
        this.dialogVisible = false;
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
      },
      // 获取专辑列表
      getAlbumList() {
        this.$workbenApi.albumList().then(res => {
          if (res.code == 1000) {
            this.options = res.result
          }
        })
      },
      // 动态加载分类
      typeLazyLoad(node, resolve) {
        if(!this.editCascader) return
        const { level } = node;
        if (level === 0) {
          this.$workbenApi.catList().then(res => {
            let firstNodes = res.result;
            firstNodes = firstNodes.map(item => ({
              value: item.id.toString(),
              label: item.name,
              leaf: level >= 1
            }));
            resolve(firstNodes);
          })
        } else {
          let obj = {
            parent_id: node.value
          }
          this.$workbenApi.catList(obj).then(res => {
            const secondNodes = res.result.map(item => ({
              value: item.id.toString(),
              label: item.name,
              leaf: level >= 1
            }));
            resolve(secondNodes);
          })
        }
      },
      // 编辑台本详情
      getDetails() {
        var that = this
        that.mainLoading = true
        that.$workbenApi.convertToTextDetail(that.id).then(res => {
          that.mainLoading = false
          if (res.code == 1000) {
             setTimeout(function(){
               that.form = res.result
               that.editCascader++
             },500)
          }else{
            that.$errMsg(res.message)
          }
        })
      },
      // 引用台本详情
      quoteDetails(val){
        var that = this
        that.mainLoading=true
        that.$workbenApi.marketDetails(val).then(res => {
          that.mainLoading=false
          if (res.code == 1000) {
            setTimeout(function(){
              that.form = res.result
              that.editCascader++
            },500)
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      //
      changeBtn(val){
        var num = val.length-1
        this.form.category_id=val[num]
      },
    },
  };
</script>

<style scoped lang="scss">
  .drama_main {
    display: flex;
    min-width: 1200px;
    font-size: 14px;

    .drama_main_l {
      width: 342px;
      height: 680px;
      background: #ffffff;
      border-radius: 10px;
      margin-right: 20px;
      padding: 30px 20px;

      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
      }

      .line {
        width: 100%;
        height: 1px;
        background: #ededed;
        margin: 14px 0 30px 0;
      }

      .cover {
        width: 160px;
        height: 210px;
        position: relative;
        margin: 0 auto 7px;

        img {
          width: 100%;
          height: 100%;
        }

        .cover_b {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 160px;
          height: 40px;
          background: rgba(0, 0, 0, 0.5);
          line-height: 40px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
      }

      .tit {
        color: #333333;
        line-height: 20px;
        padding-bottom: 10px;
        margin-top: 10px;

        span {
          color: #e00c25;
        }
      }

      .input {
        width: 302px;
      }
    }

    .drama_main_r {
      width: calc(100% - 362px);
      padding: 30px 90px 30px 30px;
      height: 680px;
      background: #ffffff;
      border-radius: 10px;

      .tit {
        font-size: 14px;
        color: #333333;
        padding-top: 25px;
        padding-bottom: 12px;
      }

      .audio_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        padding: 10px 20px;
        background: #f9f9f9;
        border-radius: 2px;

        audio {
          width: 100%;
          height: 50px;
        }

        .btn {
          width: 84px;
          height: 30px;
          background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
          border-radius: 2px;
          margin-left: 28px;
        }

        p {
          font-size: 14px;
          color: #333333;

          span {
            cursor: pointer;
            color: #2e4bf2;
          }
        }
      }
    }
  }

  .drama_footer {
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 0 20px;
  }
</style>
